import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrimOnBlurDirective} from "./directives/trim-on-blur.directive";
import {FileReaderComponent} from "./components/file-reader/file-reader.component";
import {HelpInfoComponent} from "./components/help-info/help-info.component";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {
    FormCertificateVerificationComponent
} from './components/form-certificate-verification/form-certificate-verification.component';
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {
    SummaryCertificateVerificationResultComponent
} from './components/summary-certificate-verification-result/summary-certificate-verification-result.component';
import {ReCaptchaComponent} from './components/re-captcha/re-captcha.component';
import {RecaptchaModule} from "ng-recaptcha";
import {LocalizedStringTranslatePipe} from './pipes/localized-string-translate.pipe';
import {VolumePipe} from "./pipes/volume.pipe";
import {FormContactComponent} from './components/form-contact/form-contact.component';


@NgModule({
    declarations: [
        TrimOnBlurDirective,
        FileReaderComponent,
        HelpInfoComponent,
        FormCertificateVerificationComponent,
        SummaryCertificateVerificationResultComponent,
        ReCaptchaComponent,
        LocalizedStringTranslatePipe,
        VolumePipe,
        FormContactComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        RecaptchaModule
    ],
    exports: [
        TrimOnBlurDirective,
        FileReaderComponent,
        HelpInfoComponent,
        FormCertificateVerificationComponent,
        SummaryCertificateVerificationResultComponent,
        FormContactComponent
    ]
})
export class SharedModule {
}
