<form [formGroup]="formGroup" class="d-flex flex-column">

    <p class="text-center mb-2">
        {{'contact.form.replyTo.label' | translate}}
        <app-help-info [label]="'contact.form.replyTo.tooltip' | translate"></app-help-info>
    </p>
    <div class="input-group mx-auto w-auto mb-3">
        <input appTrimOnBlur
               class="mx-auto form-control"
               formControlName="replyTo"
               name="replyTo"
               type="text"/>
    </div>

    <p class="text-center mb-2">
        {{'contact.form.message.label' | translate}}
        <app-help-info [label]="'contact.form.message.tooltip' | translate"></app-help-info>
    </p>
    <div class="input-group mx-auto w-auto mb-3">
        <textarea class="mx-auto form-control"
                  formControlName="message"
                  name="message"
                  rows="5"
                  type="text"></textarea>
    </div>

    <p class="text-center mb-2">
        {{'contact.form.file.label' | translate}}
        <app-help-info [label]="'contact.form.file.tooltip' | translate"></app-help-info>
    </p>
    <div class="input-group mx-auto w-auto mb-2">
        <app-file-reader [maxSize]="$maxSizeContact | async"

                         class="mx-auto w-auto"
                         formControlName="file">
        </app-file-reader>
    </div>

    <ng-container *ngIf="$siteKeyCaptcha | async as token">
        <app-re-captcha (resolved)="resolvedCaptcha($event)" [siteKey]="token">
        </app-re-captcha>
    </ng-container>
    <button (click)="onSubmit()"
            [disabled]="formGroup.invalid"
            class="mt-4 btn btn-warning mx-auto col-12 col-md-6 border">{{'contact.form.submit' | translate}}</button>
</form>
