import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'volume'
})
export class VolumePipe implements PipeTransform {

    transform(value: number, magnitudeOrdreMin: number, magnitudeOrdreMax?: number): number {
        let res = value;
        if (magnitudeOrdreMin > 0) {
            res /= Math.pow(10, magnitudeOrdreMin);
            res = Math.floor(res);
        }
        if (magnitudeOrdreMax) {
            res %= Math.pow(10, magnitudeOrdreMax);
        }

        return res;
    }

}
