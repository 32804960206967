<ng-container *ngIf="result.reference">
    <p class="mb-0 mx-auto text-center">{{'verification.reference-title' | translate}}</p>
    <p class="mb-5 mx-auto text-center font-weight-bold">{{result.reference}}</p>
</ng-container>

<div class="mb-5">

    <p class="mb-2 h4 mx-auto text-center">{{'verification.result.title' | translate}}</p>

    <p *ngIf="result.isFound && result.isValid" class="mx-auto text-center">
        <i class="bi bi-2x bi-check-circle-fill text-success mr-2"></i>
        <span
            class="">{{'verification.result.items.foundAndInvalid' | translate: {reference: result.certificat.reference} }}</span>
    </p>
    <p *ngIf="result.byFile && result.bySignedFile" class="mx-auto text-center">
        <i class="bi bi-2x bi-check-circle-fill text-success mr-2"></i>
        <span
            class="">{{'verification.result.items.isSignedFile' | translate}}</span>
    </p>
    <p *ngIf="result.byFile && !result.bySignedFile" class="mx-auto text-center">
        <i class="bi bi-2x bi-x-circle-fill text-danger mr-2"></i>
        <span class="">{{'verification.result.items.fileInvalid' | translate}}</span>
    </p>
    <p *ngIf="!result.isFound" class="mx-auto text-center">
        <i class="bi bi-2x bi-x-circle-fill text-danger mr-2"></i>
        <span
            class="">{{'verification.result.items.referenceNotFound' | translate}}</span>
    </p>
    <p *ngIf="result.isFound && !result.isValid" class="mx-auto text-center">
        <i class="bi bi-2x bi-x-circle-fill text-danger mr-2"></i>
        <span
            class="">{{'verification.result.items.referenceInvalid' | translate: {reference: result.reference} }}</span>
    </p>
</div>

<ng-container *ngIf="result.certificat as certificate; else certificateNotFoundTemplate">
    <p class="mx-auto text-center">{{'verification.result.information.title' | translate}}</p>

    <ng-container *ngIf="$locale |async as locale">
        <div *ngIf="certificate.dateValide" class="row">
            <div class="col-6">
                <p class="mx-auto text-right">{{'verification.result.information.dateValide' | translate}}</p>
            </div>
            <div class="col-6">
                <p class="mx-auto text-left">
                    {{certificate.dateValide | date: 'shortDate':undefined:locale}}
                    {{certificate.dateValide | date: 'longTime':undefined:locale}}
                </p>
            </div>
        </div>
        <div *ngIf="certificate.dateInvalide && !result.isValid" class="row">
            <div class="col-6">
                <p class="mx-auto text-right">{{'verification.result.information.dateInvalide' | translate}}</p>
            </div>
            <div class="col-6">
                <p class="text-danger mx-auto text-left">
                    {{certificate.dateInvalide | date: 'shortDate':undefined:locale}}
                    {{certificate.dateInvalide | date: 'longTime':undefined:locale}}
                </p>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-6">
            <p class="mx-auto text-right">{{'verification.result.information.originPays' | translate}}</p>
        </div>
        <div class="col-6">
            <p class="mx-auto text-left">{{'verification.result.information.originPaysFrance' | translate}}</p>
        </div>
    </div>
    <div *ngIf="certificate.destinationPays" class="row">
        <div class="col-6">
            <p class="mx-auto text-right">{{'verification.result.information.destinationPays' | translate}}</p>
        </div>
        <div class="col-6">
            <p class="mx-auto text-left">{{certificate.destinationPays | localizedStringTranslate}}</p>
        </div>
    </div>
    <div *ngIf="certificate.destinationCpCity" class="row">
        <div class="col-6">
            <p class="mx-auto text-right">{{'verification.result.information.destinationCpCity' | translate}}</p>
        </div>
        <div class="col-6">
            <p class="mx-auto text-left">{{certificate.destinationCpCity}}</p>
        </div>
    </div>
    <div *ngIf="certificate.totalVolumeCl" class="row">
        <div class="col-6">
            <p class="mx-auto text-right">{{'verification.result.information.totalVolumeCl' | translate}}</p>
        </div>
        <div class="col-6">
            <p class="mx-auto text-left">
                {{ certificate.totalVolumeCl | volume:4  | number:'1.0-0'}} Hl
                {{ certificate.totalVolumeCl | volume:2:2  | number:'2.0-0'}} L
                {{ certificate.totalVolumeCl | volume:0:2 | number:'2.0-0'}} Cl
            </p>
        </div>
    </div>
</ng-container>

<ng-template #certificateNotFoundTemplate>
    <p [innerHTML]="'verification.result.certificateNotFoundHtml' | translate"
       [style.border-style]="'dashed !important'"
       class="mx-auto text-center text-info border rounded-lg col-12 col-md-6 border-dark p-4"></p>
</ng-template>
