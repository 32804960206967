import {Observable, Subscription} from "rxjs";

export class SubscriptionHandler {

    private _subscription: Subscription = new Subscription();

    protected subscribe(obs: Observable<any>, ...args: any[]) {
        this._subscription.add(obs.subscribe(...args));
    }

    protected unsubscribe() {
        this._subscription.unsubscribe()
    }
}
