<div class="container-fluid d-flex flex-column h-100 pt-5  pt-md-2">
    <app-header class="row"></app-header>

    <main class="row justify-content-center">
        <router-outlet></router-outlet>
    </main>

    <div class="row flex-fill"></div>
    <app-footer class="row"></app-footer>
</div>

<app-language-selector></app-language-selector>
