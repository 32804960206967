<form [formGroup]="formGroup" class="d-flex flex-column">

    <ng-container *ngIf="reference">
        <p class="mb-0 mx-auto text-center">{{'verification.reference-title' | translate}}</p>
        <p class="mb-4 mx-auto text-center font-weight-bold">{{reference}}</p>
    </ng-container>

    <h1 class="text-warning mx-auto">1</h1>
    <p class="text-center mb-2">
        {{'verification.form.url.label' | translate}}
        <app-help-info [label]="'verification.form.url.tooltip' | translate"></app-help-info>
    </p>
    <div class="input-group mx-auto w-auto mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text bg-white">
                <i class="bi bi-lock-fill"></i>
            </span>
        </div>
        <input class="mx-auto form-control bg-white border-left-0 w-auto" formControlName="url" name="url"
               readonly type="text"/>
    </div>

    <ng-container *ngIf="!reference">
        <h1 class="text-warning mx-auto">2</h1>
        <p class="text-center mb-2">
            {{'verification.form.file.label' | translate}}
            <app-help-info [label]="'verification.form.file.tooltip' | translate"></app-help-info>
        </p>
        <div class="input-group mx-auto w-auto mb-2">
            <app-file-reader class="mx-auto" formControlName="file"
                             [acceptFileTypes]="'.pdf'"
                             [supportMimeTypes]="'application/pdf'"
                             [mimeTypeErrorMessage]="'Le fichier doit être un PDF.'">
            </app-file-reader>
        </div>
        <p class="text-center h3 text-uppercase">
            {{'general.or' | translate}}
        </p>
        <p class="text-center mb-2">
            {{'verification.form.reference.label' | translate}}
            <app-help-info [label]="'verification.form.reference.tooltip' | translate"></app-help-info>
        </p>
        <div class="input-group mx-auto w-auto mb-3">
            <input appTrimOnBlur
                   class="mx-auto form-control"
                   formControlName="reference"
                   name="reference"
                   type="text"/>
        </div>
    </ng-container>

    <ng-container *ngIf="$siteKeyCaptcha | async as token">
        <h1 class="text-warning mx-auto">{{reference ? '2' : '3'}}</h1>
        <app-re-captcha (resolved)="resolvedCaptcha($event)" [siteKey]="token">
        </app-re-captcha>
    </ng-container>
    <button (click)="submit()"
            [disabled]="formGroup.invalid"
            class="mt-4 btn btn-warning mx-auto col-12 col-md-6 border">{{'verification.form.submit' | translate}}</button>
</form>
