import {Component} from '@angular/core';
import {LanguageService} from "../../services/language.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
    public currentLanguage$: BehaviorSubject<string>;

    public i18nPrefix: string = 'languageSelector.';
    availableLanguages: any;

    constructor(private readonly languageService: LanguageService) {
        this.currentLanguage$ = this.languageService.currentLanguageSubject$;

        this.availableLanguages = this.languageService.availableLanguages;
    }

    select(language: string) {
        if (this.languageService.availableLanguages.includes(language)) {
            this.languageService.use(language);
        }
    }
}
