export interface SatelliteConfigurationInterface {
    name: string;
    url: string;
    hasCaptcha: boolean;
    enableGoogleAnalytics: boolean;
    showTitleOnTopOfPage: boolean;
    siteKeyCaptcha: string;
    contactFileMaxSize: number;
}

export class SatelliteConfigurationModel {
    constructor(
        public name: string,
        public url: string,
        public hasCaptcha: boolean,
        public enableGoogleAnalytics: boolean,
        public showTitleOnTopOfPage: boolean,
        public siteKeyCaptcha: string,
        public contactFileMaxSize: number
    ) {
    }

    static fromInterface(itf: SatelliteConfigurationInterface) {
        return new SatelliteConfigurationModel(
            itf.name,
            itf.url,
            itf.hasCaptcha,
            itf.enableGoogleAnalytics,
            itf.showTitleOnTopOfPage,
            itf.siteKeyCaptcha,
            itf.contactFileMaxSize,
        );
    }
}
