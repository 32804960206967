import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
    selector: 'app-re-captcha',
    templateUrl: './re-captcha.component.html',
    styleUrls: ['./re-captcha.component.scss'],
    providers: []
})
export class ReCaptchaComponent {

    @HostBinding()
    public className = 'mx-auto w-auto'

    @Input()
    public siteKey: string;

    @Output()
    public resolved: EventEmitter<string | false> = new EventEmitter<string | false>();

    constructor() {
    }

    onChange(result: string | false) {
        this.resolved.emit(result);
    }
}
