import {Component, Input} from '@angular/core';
import {CertificateVerificationResultModel} from "../../models/certificate-verification-result.model";
import {Observable} from "rxjs";
import {LanguageService} from "../../../core/services/language.service";

@Component({
    selector: 'app-summary-certificate-verification-result',
    templateUrl: './summary-certificate-verification-result.component.html',
    styleUrls: ['./summary-certificate-verification-result.component.scss']
})
export class SummaryCertificateVerificationResultComponent {

    @Input()
    public result: CertificateVerificationResultModel;

    $locale: Observable<string> = this.languageService.currentLanguageSubject$;

    constructor(private readonly languageService: LanguageService) {
    }

}
