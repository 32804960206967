import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {SatelliteConfigurationService} from "../../shared/services/satellite-configuration.service";
import {catchError, map, pluck, switchMap, tap, throttleTime} from "rxjs/operators";
import {SubscriptionHandler} from "../../shared/class/subscription-handler.class";
import {CertificatVerificationService} from "../../shared/services/certificat-verification.service";
import {CertificateVerificationInputInterface} from "../../shared/models/check-certificat-input.model";
import {CertificateVerificationResultModel} from "../../shared/models/certificate-verification-result.model";
import {Observable, Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-certificate-verification',
    templateUrl: './certificate-verification.component.html',
    styleUrls: ['./certificate-verification.component.scss']
})
export class CertificateVerificationComponent extends SubscriptionHandler implements OnInit, OnDestroy {

    public result: CertificateVerificationResultModel = undefined;
    public submitSubject: Subject<CertificateVerificationInputInterface> = new Subject<CertificateVerificationInputInterface>();
    public $reference: Observable<string>;

    @HostBinding('class')
    private className = 'col-12 col-md-8';

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly satelliteConfigurationService: SatelliteConfigurationService,
        private readonly certificatVerificationService: CertificatVerificationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router
    ) {
        super()
    }

    public ngOnInit(): void {
        this.subscribe(
            this.submitSubject.pipe(
                throttleTime(300),
                switchMap((input) => this.certificatVerificationService.checkCertificate(input)),
                catchError((e, originalObs) => originalObs.pipe(
                    map(() => undefined)
                )),
                tap((result) => this.result = result)
            )
        );

        this.$reference = this.activatedRoute.params.pipe(
            pluck('reference'),
            map(reference => reference ?? '')
        )
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public submit(input: CertificateVerificationInputInterface): void {
        this.submitSubject.next(input);
    }

    public reset(): void {
        this.result = undefined;
        this.router.navigate(['/']);
    }
}
