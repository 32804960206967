import {
    CertificateVerificationSummarizeInterface,
    CertificateVerificationSummarizeModel
} from "./certificate-verification-summarize.model";

export interface CertificateVerificationResultInterface {
    reference: string;
    certificat: CertificateVerificationSummarizeInterface;

    isFound: boolean;
    isValid: boolean;

    byFile: boolean;
    bySignedFile: boolean;
}

export class CertificateVerificationResultModel {
    constructor(
        public reference: string,
        public certificat: CertificateVerificationSummarizeModel,
        public isFound: boolean,
        public isValid: boolean,
        public byFile: boolean,
        public bySignedFile: boolean
    ) {
    }

    static fromInterface(itf: CertificateVerificationResultInterface) {
        return new CertificateVerificationResultModel(
            itf.reference,
            itf.certificat ? CertificateVerificationSummarizeModel.fromInterface(itf.certificat) : null,
            itf.isFound,
            itf.isValid,
            itf.byFile,
            itf.bySignedFile
        );
    }
}
