import {Routes} from '@angular/router';
import {CertificateVerificationComponent} from './pages/certificate-verification/certificate-verification.component';
import {ContactComponent} from './pages/contact/contact.component';
import {InformationComponent} from './pages/information/information.component';

export let routes: Routes = [
    {path: '', pathMatch: 'full', component: CertificateVerificationComponent},
    {path: 'ref/:reference', component: CertificateVerificationComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'information', component: InformationComponent},
    {path: '**', redirectTo: ''}
];
