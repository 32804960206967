import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors} from "@angular/forms";
import {Observable} from "rxjs";
import {CertificateVerificationInputInterface} from "../../models/check-certificat-input.model";
import {filter, pluck, tap} from "rxjs/operators";
import {SatelliteConfigurationService} from "../../services/satellite-configuration.service";
import {SatelliteConfigurationModel} from "../../models/satellite-configuration.model";
import {SubscriptionHandler} from "../../class/subscription-handler.class";
import {LanguageService} from "../../../core/services/language.service";

@Component({
    selector: 'app-form-certificate-verification',
    templateUrl: './form-certificate-verification.component.html',
    styleUrls: ['./form-certificate-verification.component.scss']
})
export class FormCertificateVerificationComponent extends SubscriptionHandler implements OnInit, OnDestroy {

    public formGroup: FormGroup;
    public $siteKeyCaptcha: Observable<string>;

    @Input()
    public reference: string = '';

    @Output()
    public onSubmit: EventEmitter<CertificateVerificationInputInterface> = new EventEmitter<CertificateVerificationInputInterface>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly languageService: LanguageService,
        private readonly satelliteConfigurationService: SatelliteConfigurationService
    ) {
        super();
    }

    private static formGroupValidator(formGroup: AbstractControl): ValidationErrors | null {
        const catchaIsValid: boolean = formGroup.get('captcha').value !== false;
        const hasReference: boolean = formGroup.get('reference').value != '';
        const hasFile: boolean = formGroup.get('file').value != null;
        return catchaIsValid && (hasReference || hasFile) ? null : {error: true};
    };

    public ngOnInit(): void {

        this.formGroup = this.buildFormGroup();
        this.$siteKeyCaptcha = this.satelliteConfigurationService.$configurations.pipe(
            filter((configuration) => configuration.hasCaptcha),
            pluck('siteKeyCaptcha')
        );

        this.subscribe(
            this.satelliteConfigurationService.$configurations.pipe(
                tap((configuration: SatelliteConfigurationModel) => {
                    this.formGroup.get("url").patchValue(configuration.url);

                    if (configuration.hasCaptcha) {
                        this.formGroup.get('captcha').setValue(false);
                    }
                })
            )
        );

        this.subscribe(
            this.formGroup.get('file').valueChanges.pipe(
                filter((file: File) => file != null),
                tap(() => this.formGroup.get('reference').setValue(''))
            )
        );

        this.subscribe(
            this.formGroup.get('reference').valueChanges.pipe(
                filter((reference: string) => reference != ''),
                tap(() => this.formGroup.get('file').setValue(null)),
            )
        );
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public resolvedCaptcha(result: string | false) {
        this.formGroup.get('captcha').setValue(result);
    }

    public submit() {
        this.onSubmit.emit({
            reference: this.formGroup.value.reference,
            file: this.formGroup.value.file,
            captcha: this.formGroup.value.captcha
        });
    }

    private buildFormGroup(): FormGroup {
        return this.formBuilder.group({
            url: {value: '', disabled: true},
            file: null,
            reference: this.reference,
            captcha: undefined,
        }, {validators: [FormCertificateVerificationComponent.formGroupValidator]})
    }
}
