<div class="input-group mb-3">
    <div class="input-group-prepend bg-white">
        <i (click)="fileInput.click()" class="input-group-text bi bg-white bi-plus-circle-fill"></i>
    </div>
    <div class="custom-file">
        <input #fileInput
               (change)="onFileSelected($event)"
               [name]="name"
               [accept]="acceptFileTypes"
               class="custom-file-input"
               id="fileInput"
               type="file">
        <label class="custom-file-label"
               for="fileInput">{{file?.name ?? ('general.file-placeholder' | translate)}}</label>
    </div>
</div>
<div *ngIf="errorType && mimeTypeErrorMessage" class="alert alert-danger">
    {{mimeTypeErrorMessage}}
</div>
<div *ngIf="error" class="alert alert-danger">
    {{error}}
</div>
<div *ngIf="errorSize" class="alert alert-danger">
    Le fichier ne doit pas dépasser les {{(maxSize/1048576) | number:'1.0-2'}}&nbsp;Mo.
</div>
