import {Injectable} from '@angular/core';
import {SatelliteConfigurationService} from "../../shared/services/satellite-configuration.service";

declare global {
    interface Window {
        dataLayer: Array<any>;
        gtag: (...args: any) => void;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private gTagUrl = "https://www.googletagmanager.com/gtag/js?id=G-EJ0P3757KG";


    constructor(protected satelliteConfigurationService: SatelliteConfigurationService) {
    }

    init() {
        this.satelliteConfigurationService.$configurations.subscribe(
            value => {
                if (value.enableGoogleAnalytics) {

                    let scriptElement = window.document.createElement("script");
                    scriptElement.src = this.gTagUrl;
                    scriptElement.async = true;
                    window.document.head.append(scriptElement);

                    scriptElement = window.document.createElement("script");
                    scriptElement.append("window.dataLayer = window.dataLayer || [];");
                    scriptElement.append("function gtag(){dataLayer.push(arguments);}");
                    scriptElement.append("gtag('js', new Date());");
                    scriptElement.append("gtag('config', 'G-EJ0P3757KG');");
                    window.document.head.append(scriptElement);
                }
            }
        )

    }
}
