import {Component} from '@angular/core';
import {SatelliteConfigurationService} from "../../../shared/services/satellite-configuration.service";
import {filter, pluck} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    public $instanceName: Observable<string>;

    constructor(private readonly satelliteConfigurationService: SatelliteConfigurationService) {

        this.$instanceName = this.satelliteConfigurationService.$configurations.pipe(
            filter((configuration) => configuration.showTitleOnTopOfPage),
            pluck('name')
        );
    }

}
