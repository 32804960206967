import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxLocalStorageModule} from 'ngx-localstorage';
import {pluck} from 'rxjs/operators';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './core/components/app.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {HeaderComponent} from './core/components/header/header.component';
import {LanguageService} from './core/services/language.service';
import {SatelliteConfigurationService} from './shared/services/satellite-configuration.service';
import {CertificateVerificationComponent} from './pages/certificate-verification/certificate-verification.component';
import {ContactComponent} from './pages/contact/contact.component';
import {InformationComponent} from './pages/information/information.component';
import {LanguageSelectorComponent} from './core/components/language-selector/language-selector.component';
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "./shared/shared.module";
import {RECAPTCHA_BASE_URL, RECAPTCHA_LANGUAGE} from "ng-recaptcha";
import {registerLocaleData} from "@angular/common";

import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeJa from '@angular/common/locales/ja';
import localeRu from '@angular/common/locales/ru';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant';
import {AnalyticsService} from "./core/services/analytics.service";
import {environment} from "../environments/environment";

registerLocaleData(localeFr, 'fr')
registerLocaleData(localeEn, 'en')
registerLocaleData(localeJa, 'ja')
registerLocaleData(localeRu, 'ru')
registerLocaleData(localeZhHans, 'cs')
registerLocaleData(localeZhHant, 'ct')

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/locales/', '.json');
}

export const languageToReCaptchaLanguageCode = {
    cs: 'zh-CN',
    ct: 'zh-TW',
    en: 'en',
    fr: 'fr',
    ja: 'ja',
    ru: 'ru'
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        InformationComponent,
        CertificateVerificationComponent,
        ContactComponent,
        LanguageSelectorComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NgxLocalStorageModule.forRoot({
            prefix: 'BNIC'
        }),
        NgbDropdownModule,
        ReactiveFormsModule,
        SharedModule
    ],
    providers: [
        {
            provide: RECAPTCHA_BASE_URL,
            useValue: environment.recaptchaUrl
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: (languageService: LanguageService) => {
                return languageToReCaptchaLanguageCode[languageService.currentLanguage]
            },
            deps: [LanguageService]
        }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(
        private readonly satelliteConfigurationService: SatelliteConfigurationService,
        private readonly title: Title,
        private readonly languageService: LanguageService,
        private readonly analyticsService: AnalyticsService,
    ) {
        this.initTitle();
        this.languageService.initLanguage();
        this.analyticsService.init();
    }

    private initTitle(): void {
        this.satelliteConfigurationService.$configurations.pipe(
            pluck('name')
        ).subscribe((name: string) => {
            this.title.setTitle(name);
        });
    }
}
