import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {
    CertificateVerificationResultInterface,
    CertificateVerificationResultModel
} from "../models/certificate-verification-result.model";
import {CertificateVerificationInputInterface} from "../models/check-certificat-input.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {LanguageService} from "../../core/services/language.service";

@Injectable({
    providedIn: 'root'
})
export class CertificatVerificationService {

    private checkCertificateBaseUrl: string = environment.restApiBaseUrl + '/check-certificat';

    constructor(
        private readonly httpClient: HttpClient,
        private readonly languageService: LanguageService,
    ) {
    }

    checkCertificate(input: CertificateVerificationInputInterface): Observable<CertificateVerificationResultModel> {
        if (input.file != null && input.reference != '') {
            throw new Error('can\'t check by reference and file simultaneous !')
        }
        if (input.reference == '' && input.file == null) {
            throw new Error('can\'t find reference or file !')
        }
        let endpoint = this.checkCertificateBaseUrl;

        const formData: any = new FormData();
        if (input.reference != '') {
            formData.append('reference', input.reference ? input.reference : null);
        }
        if (input.file != null) {
            formData.append('file', input.file ? input.file.toFile() : null);
            formData.append('mimetype', input.file ? input.file.mimeType : null);
        }
        if (input.captcha !== undefined) {
            formData.append('captcha', input.captcha ? input.captcha : null);
        }

        return this.httpClient.post(endpoint, formData, {headers: {"LOCALE": this.languageService.currentLanguage}}).pipe(
            map((apiData: CertificateVerificationResultInterface) => CertificateVerificationResultModel.fromInterface(apiData)),
        );
    }
}
