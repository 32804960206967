import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, shareReplay, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs";
import {SatelliteConfigurationInterface, SatelliteConfigurationModel} from "../models/satellite-configuration.model";


@Injectable({
    providedIn: 'root'
})
export class SatelliteConfigurationService {

    private configurationEndpoint: string = environment.restApiBaseUrl + '/configuration';

    $configurations: Observable<SatelliteConfigurationModel> = this.http.get(this.configurationEndpoint).pipe(
        map((apiData: SatelliteConfigurationInterface) => SatelliteConfigurationModel.fromInterface(apiData)),
        take(1),
        shareReplay()
    );

    constructor(
        protected http: HttpClient
    ) {
    }
}
