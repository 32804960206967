<div class="d-flex flex-column mt-4">
    <ng-container *ngIf="!result; else afterSendTemplate">
        <app-form-certificate-verification (onSubmit)="submit($event)"
                                           [reference]="$reference | async"></app-form-certificate-verification>
    </ng-container>

    <ng-template #afterSendTemplate>
        <app-summary-certificate-verification-result [result]="result"></app-summary-certificate-verification-result>

        <button (click)="reset()" class="mt-3 btn btn-warning mx-auto col-12 col-md-6 border">
            {{'general.check-another' | translate}}
        </button>

        <span [innerHTML]="'verification.contact.message-html' | translate"
              class="mt-5 mx-auto text-center text-info"></span>

        <button [routerLink]="['/','contact']" class="mt-3 btn btn-outline-secondary mx-auto col-12 col-md-6 border">
            {{'verification.contact.label' | translate}}
        </button>
    </ng-template>
</div>
