import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'ngx-localstorage';
import {pluck} from 'rxjs/operators';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public availableLanguages: Array<string> = ['fr', 'en', 'cs', 'ct', 'ru', 'ja'];

    public currentLanguage: string;

    public currentLanguageSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    public defaultLanguage: string = 'en';

    private currentLanguage$: Observable<string>;

    constructor(
        private readonly translateService: TranslateService,
        private readonly localStorageService: LocalStorageService
    ) {
    }

    public initLanguage(): void {
        this.currentLanguage$ = this.translateService.onLangChange
            .pipe(
                pluck('lang')
            );

        this.currentLanguage$.subscribe(
            (language: string) => {
                this.currentLanguageSubject$.next(language);
                this.localStorageService.set('USER_LOCALE', language);
            }
        );

        this.use(this.getInitialLanguage());
    }

    public use(language: string) {
        this.currentLanguage = language;
        this.translateService.use(language);
    }

    private getInitialLanguage(): string {
        const storedLanguage: any = this.localStorageService.get('USER_LOCALE');

        if (storedLanguage) {
            return storedLanguage;
        }

        let browserLang = this.translateService.getBrowserLang();
        if (browserLang === 'zh') {
            browserLang = 'cs'
        }

        return this.availableLanguages.includes(browserLang) ? browserLang : this.defaultLanguage;
    }
}
