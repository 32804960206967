import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

    @HostBinding('class')
    private className = 'col-12 col-md-8';

    constructor() {
    }

    ngOnInit(): void {
    }

}
