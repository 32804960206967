import {LocalizedStringInterface} from "./localized-string.model";

export interface CertificateVerificationSummarizeInterface {

    reference: string;
    dateValide: number;
    dateInvalide: number;
    destinationPays: LocalizedStringInterface;
    destinationCpCity: string;
    totalVolumeCl: number;
}

export class CertificateVerificationSummarizeModel {
    constructor(
        public reference: string,
        public dateValide: number,
        public dateInvalide: number,
        public destinationPays: LocalizedStringInterface,
        public destinationCpCity: string,
        public totalVolumeCl: number
    ) {
    }

    static fromInterface(itf: CertificateVerificationSummarizeInterface) {
        return new CertificateVerificationSummarizeModel(
            itf.reference,
            itf.dateValide,
            itf.dateInvalide,
            itf.destinationPays,
            itf.destinationCpCity,
            itf.totalVolumeCl
        );
    }
}
