import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {mapTo} from "rxjs/operators";
import {ContactInputInterface} from "../models/contact-input.model";

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private sendContactMailEndpoint: string = environment.restApiBaseUrl + '/contact';

    constructor(private readonly httpClient: HttpClient) {
    }

    sendContact(input: ContactInputInterface): Observable<boolean> {
        if (input.replyTo == '' || input.message == '') {
            throw new Error('Required replyTo and message !')
        }
        let endpoint = this.sendContactMailEndpoint;

        const formData: any = new FormData();
        formData.append('replyTo', input.replyTo);
        formData.append('message', this.nl2br(input.message));
        if (input.file) {
            formData.append('file.data', input.file.toFile());
            formData.append('file.name', input.file.name);
            formData.append('file.mimetype', input.file.mimeType);
        }

        return this.httpClient.post(endpoint, formData).pipe(
            mapTo(true),
        )
    }


    /**
     * Remplace les changements de ligne du texte
     * Paramètre : string str Chaîne à traiter
     **/
    nl2br(str: string) {
        return str = str.replace(/\n/g,'<br />\n');
    }
}
