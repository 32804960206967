import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from "../../core/services/language.service";
import {LocalizedStringInterface} from "../models/localized-string.model";

@Pipe({
    name: 'localizedStringTranslate',
    pure: false
})
export class LocalizedStringTranslatePipe implements PipeTransform {

    constructor(private readonly languageService: LanguageService) {
    }

    transform(value: LocalizedStringInterface): string {
        if (!value) {
            return ""
        }
        return value[this.languageService.currentLanguage] ?? value[this.languageService.defaultLanguage];
    }

}
