<div *ngIf="!isSent; else afterSendTemplate" class="d-flex flex-column mt-3">

    <ng-container *ngIf="!isSent; else afterSendTemplate">
        <span class="mt-2 mb-4 mx-auto text-center">{{'contact.title' | translate}}</span>
        <app-form-contact (submit)="onSubmit($event)"></app-form-contact>
    </ng-container>
</div>

<ng-template #afterSendTemplate>
    <div class="d-flex flex-column">
        <p class="mt-2 mx-auto text-center">{{'contact.result.thank-you' | translate}}</p>
        <p class="mt-4 mx-auto text-center">{{'contact.result.message' | translate}}</p>

        <button [routerLink]="['..']" class="mt-3 btn btn-warning mx-auto col-12 col-md-6 border">
            {{'general.check-another' | translate}}
        </button>
    </div>
</ng-template>
