import {Component, forwardRef, Input} from '@angular/core';
import {FileModel} from "../../models/file.model";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-file-reader',
    templateUrl: './file-reader.component.html',
    styleUrls: ['./file-reader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileReaderComponent),
            multi: true
        }
    ]
})
export class FileReaderComponent implements ControlValueAccessor {

    error: string;

    progress = -1;

    errorSize = false;

    errorType = false;

    @Input()
    name: string;

    @Input()
    acceptFileTypes: string;

    @Input()
    supportMimeTypes: string = '(image\/.+)|(application\/pdf)';

    @Input()
    mimeTypeErrorMessage: string = 'Le fichier doit être un PDF ou une image.';

    @Input()
    maxSize: number = 0;

    fileReader: FileReader = new FileReader();
    file: FileModel;

    constructor() {

        this.fileReader.onerror = () => {
            this.error = this.fileReader.error.message;
        };
        this.fileReader.onloadstart = () => {
            this.progress = 0;
        };
        this.fileReader.onprogress = (evt) => {
            this.progress = Math.round(100.0 * evt.loaded / evt.total);
        };
        this.fileReader.onloadend = () => {
            const buffer: ArrayBuffer = this.fileReader.result as ArrayBuffer;
            this.file.data = new Uint8Array(buffer);
            this.progress = -1;
            this.onChange(this.file);
        };
    }


    onFileSelected($event: any) {
        const files = $event.target.files;
        this.file = null;
        this.errorSize = null;
        this.errorType = null;
        this.error = null;
        if (files && files[0]) {
            const file = files[0];
            if (!this.isMimeTypeValid(file)){
                return;
            }
            if (!this.isMaxSizeValid(file)){
                return;
            }
            this.file = new FileModel();
            this.file.name = file.name;
            this.file.mimeType = file.type;
            this.fileReader.readAsArrayBuffer(file);
        } else {
            this.onChange(null);
        }
    }

    private isMimeTypeValid(file): boolean {
        let supportMimeTypes = this.supportMimeTypes ?? '';
        this.errorType = supportMimeTypes != '' && supportMimeTypes.split(',')
            .filter(value => new RegExp(value).test(file.type))
            .length == 0;
        return !this.errorType;
    }

    private isMaxSizeValid(file): boolean {
        let maxSize = this.maxSize ?? 0;
        this.errorSize = maxSize != 0 && file.size > maxSize
        return !this.errorSize;
    }

    onChange: any = () => {
    };
    onTouch: any = () => {
    };

    writeValue(obj: any): void {
        if (obj == null) {
            this.file = null;
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}

