import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {LanguageService} from "../../../core/services/language.service";
import {SatelliteConfigurationService} from "../../services/satellite-configuration.service";
import {filter, pluck, tap} from "rxjs/operators";
import {SatelliteConfigurationModel} from "../../models/satellite-configuration.model";
import {ContactInputInterface} from "../../models/contact-input.model";
import {SubscriptionHandler} from "../../class/subscription-handler.class";

@Component({
    selector: 'app-form-contact',
    templateUrl: './form-contact.component.html',
    styleUrls: ['./form-contact.component.scss']
})
export class FormContactComponent extends SubscriptionHandler implements OnInit, OnDestroy {

    public formGroup: FormGroup;
    public $siteKeyCaptcha: Observable<string>;
    public $maxSizeContact: Observable<number>;

    @Output()
    public submit: EventEmitter<ContactInputInterface> = new EventEmitter<ContactInputInterface>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly languageService: LanguageService,
        private readonly satelliteConfigurationService: SatelliteConfigurationService
    ) {
        super();
    }

    private static formGroupValidator(formGroup: AbstractControl): ValidationErrors | null {
        const catchaIsValid: boolean = formGroup.get('captcha').value !== false;
        return catchaIsValid ? null : {error: true};
    };

    public ngOnInit(): void {

        this.formGroup = this.buildFormGroup();

        this.$siteKeyCaptcha = this.satelliteConfigurationService.$configurations.pipe(
            filter((configuration) => configuration.hasCaptcha),
            pluck('siteKeyCaptcha')
        );
        this.$maxSizeContact = this.satelliteConfigurationService.$configurations.pipe(
            pluck('contactFileMaxSize')
        );

        this.subscribe(
            this.satelliteConfigurationService.$configurations.pipe(
                tap((configuration: SatelliteConfigurationModel) => {
                    if (configuration.hasCaptcha) {
                        this.formGroup.get('captcha').setValue(false);
                    }
                })
            )
        );
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public resolvedCaptcha(result: string | false) {
        this.formGroup.get('captcha').setValue(result);
    }

    public onSubmit() {
        this.submit.emit({
            replyTo: this.formGroup.value.replyTo,
            message: this.formGroup.value.message,
            file: this.formGroup.value.file,
            captcha: this.formGroup.value.captcha
        });
    }

    private buildFormGroup(): FormGroup {
        return this.formBuilder.group({
            replyTo: ['', [Validators.required, Validators.email]],
            message: ['', [Validators.required, Validators.maxLength(10000)]],
            file: null,
            captcha: undefined,
        }, {validators: [FormContactComponent.formGroupValidator]})
    }
}
