import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: 'input[appTrimOnBlur]'
})
export class TrimOnBlurDirective {

    constructor(private readonly inputElement: ElementRef) {
    }

    @HostListener('blur')
    public trimOnBlur() {
        let originalValue = this.inputElement.nativeElement.value;

        let trimmedValue = (originalValue ?? '').trim();
        if (originalValue != trimmedValue) {
            this.inputElement.nativeElement.value = trimmedValue;
            this.inputElement.nativeElement.dispatchEvent(new Event('input'));
        }
    }

}
