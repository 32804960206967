<div *ngIf="currentLanguage$ | async as currentLanguage" [placement]="'bottom'" class="d-inline-block small m-2"
     ngbDropdown>
    <span class="border-0" ngbDropdownToggle type="button">
        <i class="bi bi-translate"></i> {{ i18nPrefix + currentLanguage | translate}}</span>
    <div ngbDropdownMenu>
        <ng-container *ngFor="let language of availableLanguages">
            <button (click)="select(language)" class="small px-3"
                    ngbDropdownItem>{{i18nPrefix + language | translate}}</button>
        </ng-container>
    </div>
</div>
