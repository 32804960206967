import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-help-info',
    templateUrl: './help-info.component.html',
    styleUrls: ['./help-info.component.scss']
})
export class HelpInfoComponent {

    @Input()
    public label: string;

    constructor() {
    }

}
