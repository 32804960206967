import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ContactInputInterface} from "../../shared/models/contact-input.model";
import {SubscriptionHandler} from "../../shared/class/subscription-handler.class";
import {catchError, map, switchMap, tap, throttleTime} from "rxjs/operators";
import {of, Subject} from "rxjs";
import {ContactService} from "../../shared/services/contact.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent extends SubscriptionHandler implements OnInit, OnDestroy {

    public isSent = false;
    public submitSubject: Subject<ContactInputInterface> = new Subject<ContactInputInterface>();

    @HostBinding('class')
    private className = 'col-12 col-md-8';

    constructor(private readonly contactService: ContactService) {
        super();
    }

    public ngOnInit(): void {
        this.subscribe(
            this.submitSubject.pipe(
                throttleTime(300),
                switchMap((input) => this.contactService.sendContact(input)),
                catchError((e, originalObs) => originalObs.pipe(
                    map(() => false)
                )),
                tap((resultat) => this.isSent = resultat)
            )
        );
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public onSubmit(input: ContactInputInterface): void {
        this.submitSubject.next(input);
    }
}
