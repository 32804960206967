/**
 * Modèle de fichier
 */
export class FileModel {

    name: string;
    mimeType: string;
    data: Uint8Array;

    toFile(): File {
        if (!this.data) {
            return null;
        }

        return new File([new Blob([this.data], {type: this.mimeType})], this.name);
    }

}
